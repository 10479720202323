<template>
  <div>
    <div class='row my-n1'>
      <div class='col-auto my-1'>
        <button
          v-if='settings.showUserLocation'
          :class='["btn btn-sm", {"btn-primary": hasUserPosition, "btn-outline-secondary": !hasUserPosition}]'
          @click.prevent.stop='handleOnMyLocationClicked'
          >
          <fa-icon icon='location-arrow'></fa-icon>
        </button>
        <button
          class='btn btn-sm btn-secondary'
          @click.prevent.stop='handleOnResetClicked'>
          <fa-icon icon='eye'></fa-icon>
          {{translations.buttons.reset}}
        </button>

        <button
          class='btn btn-sm btn-secondary'
          @click.prevent.stop='handleOnSettingsClicked'
          >
          <fa-icon icon='cog'></fa-icon>
        </button>
      </div>
      <div class='col-12 col-md my-1' v-if='size != "small"'>
        <div
          class="input-group mb-0"
          @keydown.enter.prevent.stop
          >
          <div class='input-group-prepend'>
            <div class='input-group-text'>
              <i class='fa fa-search'></i>
            </div>
          </div>

          <GmapAutocomplete
            class='form-control form-control-sm mb-0'
            :types='["geocode"]'
            :selectFirstOnEnter='true'
            @place_changed='handlePlaceSelected($event)'
            >
          </GmapAutocomplete>
        </div>
      </div>

      <slot />
   </div>
    <SettingsModal
      ref='settingsModal'
      :settings='settings'
      :translations='translations.modal' />
  </div>
</template>

<script>

import SettingsModal from "./settings_modal"

export default {
  props: [ "translations", "settings", "hasUserPosition", "size" ],
  components: { SettingsModal },
  methods: {
    handleOnMyLocationClicked() { this.$emit('focusMyLocation') },
    handleOnResetClicked() { this.$emit("reset") },
    handleOnSettingsClicked() { this.$refs.settingsModal.open() },
    handlePlaceSelected(place) { this.$emit("placeSelected", place) },

  }
}

</script>