<template>
  <Modal ref='modal' :title='translations.title'>
    <h5 class='mt-0 mb-2'>{{ translations.general_section }}</h5>
    <CustomSwitch :label='translations.user_location_switch.label' v-model='settings.showUserLocation' />

    <h5 class='mt-3 mb-2'>{{ translations.areas_section }}</h5>
    <CustomSwitch :label='translations.area_pins_switch.label' class='mb-2' v-model='settings.areaPins' />
    <ColorSelector :translations='translations.color_selector' v-model='settings.color' />
  </Modal>
</template>

<script>

import { Modal, CustomSwitch } from "../../bootstrap"

import ColorSelector from "./color_selector"

export default {
  props: ['translations', 'settings'],
  components: { Modal, ColorSelector, CustomSwitch },
  methods: {
    open() {  this.$refs.modal.open() },
    close() { this.$refs.modal.close() }
  }
}

</script>