import { init as initCustomFileInputs } from "./custom_file_inputs"
import { init as initTooltips } from "./tooltips"
import { init as initPopovers } from "./popovers"
import { init as initModals } from "./modals"

export function start() {
  $(document).on("turbolinks:load", () => {
    initTooltips()
    initPopovers()
    initCustomFileInputs()
    initModals()
  })
}