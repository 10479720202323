import Turbolinks from "turbolinks"

const INITIAL_DELAY = 500;
const CONSECUTIVE_DELAY = 2000;

export default class StatusWatcher {
  static from($element) {
    return new StatusWatcher($element.data("watch-url"))
  }

  constructor(url) {
    this.url = url
    this.timeout = undefined
    this.running = false

    this.start()
  }

  start() {
    this.running = true
    this._setTimeoutIfRunning(INITIAL_DELAY);
  }

  stop() {
    if (this.timeout) {
      this.running = false;

      clearTimeout(this.timeout);
      this.timeout = undefined
    }
  }

  // ===================
  // = Private Methods =
  // ===================
  _setTimeoutIfRunning(delay) {
    if (this.running) {
      this.timeout = setTimeout(this._tick.bind(this), delay);
    }
  }

  _tick() {
    fetch(this.url)
    .then((resp) => resp.json())
    .then((json) => this._process(json))
    .catch((e) => {
      this._setTimeoutIfRunning(CONSECUTIVE_DELAY)
    })
  }

  _process(json) {
    if (!json.in_progress && this.running) {
      this.stop()
      Turbolinks.visit(json.redirection_url)
    } else {
      this._setTimeoutIfRunning(CONSECUTIVE_DELAY)
    }
  }
}
