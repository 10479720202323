<template>
  <div class='mb-3'>
    <Tabs>
      <NavItem :active='writing' @click='handleOnWriteClicked'>{{ translations.write }}</NavItem>
      <NavItem :active='preview' @click='handleOnPreviewClicked'>{{ translations.preview }}</NavItem>
    </Tabs>
    <div class='p-3 border border-top-0'>
      <div v-show='writing'>
        <textarea ref='textfield' class='form-control' :rows='rows' v-model='value' :id='inputId'></textarea>
        <small class='text-muted' v-if='writing' v-html='markdownHint'></small>
      </div>
      <div v-show='preview'>
        <Loader :loading='loading' :delay='false'>
          <div v-if='loadingError' class='text-center'>
            <span class='text-danger'>{{ loadingError }}</span>
          </div>
          <div class='bg-light p-3' v-else v-html='previewHTML'></div>
        </Loader>
      </div>
    </div>
  </div>
</template>

<script>

import { customFormControl } from "../mixins"

import { Tabs, NavItem } from '../components/bootstrap'
import { Loader } from '../components/utils'
import { fetchMarkdownPreview } from "../api"

export default {
  props: {
    translations: Object,
    previewUrl: {
      type: String,
      required: true,
    },
    markdownLink: {
      type: String,
      required: true
    }
  },
  mixins: [ customFormControl ],
  components: { Tabs, NavItem, Loader },
  data() {
    return {
      rows: 0,
      writing: true,
      previewStale: true,
      previewHTML: "",
      loading: false,
      loadingError: null,
    }
  },
  mounted() {
    this.rows = this.$input.attr("rows")
  },
  computed: {
    preview() { return !this.writing },
    markdownHint() { return this.translations.markdown_hint.replace("%{link}", this.markdownLink) },
  },
  watch: {
    value() {
      this.previewStale = true
    },
  },
  methods: {
    requestPreview() {
      this.loading = true
      this.loadingError = null
      this.previewStale = false

      fetchMarkdownPreview(this.previewUrl, this.value)
        .then((html) => this.previewHTML = html)
        .catch((error) => {
          this.loadingError = error
          this.previewStale = true
        })
        .finally(() => this.loading = false)
    },

    focusTextArea() {
      this.$nextTick(() => {
        this.$refs.textfield.focus()
      })
    },

    // ==================
    // = Event Handling =
    // ==================
    handleLabelClick() {
      this.writing = true
      this.focusTextArea()

    },
    handleOnWriteClicked() {
      this.writing = true
      this.focusTextArea()
    },
    handleOnPreviewClicked() {
      this.writing = false

      if (this.previewStale) {
        this.requestPreview()
      }
    }

  }
}

</script>