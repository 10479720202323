<template>
  <vue-dropzone
    :id='dropzoneId'
    ref='dropzone'
    :options='dropzoneOptions'

    :useCustomSlot='true'
    :includeStyling='false'
    @vdropzone-success='handleSuccess'
    @vdropzone-error='handleError'
    @vdropzone-thumbnail='handleThumbnail'
    >
    <div class='jumbotron vue-dz-drop-area'>
      <h4 class='text-center'>{{ translations.upload_hint }}</h4>
    </div>
  </vue-dropzone>
</template>

<script>

import VueDropzone from "vue2-dropzone"
import Rails from "rails-ujs"

import { findTarget } from "../utils/targetable"

export default {
  props: [ "translations", "uploadUrl", "paramName", "target" ],
  components: { VueDropzone },
  computed: {
    targetElement() { return findTarget(this.target, this.$el) },
    dropzoneId() { return `vue-dropzone-${this._uid}`},
    previewTemplate() {
      return `
        <div class="media mb-3">
          <div class='p-1 bg-light rounded border-0 mr-3'>
              <img data-dz-thumbnail-img class=' vue-dz-preview-image' />
          </div>

          <div class="media-body">
            <h5 class="mt-0 mb-2">
              <span data-dz-name></span>
              <small data-dz-size class='text-muted'></small>
            </h5>
            <div class="progress">
              <div class="progress-bar progress-bar-striped" role="progressbar" data-dz-uploadprogress></div>
            </div>

          </div>
        </div>
      `
    },
    dropzoneOptions() {
      const { paramName, dropzoneHeaders, previewTemplate } = this

      return {
        url: this.uploadUrl,
        capture: "image/",
        method: "POST",
        headers: dropzoneHeaders,
        paramName,
        previewTemplate,
      }
    },
    dropzoneHeaders() {
      const headers = {}

      const csrfToken = Rails.csrfToken()

      if (csrfToken) {
        headers['X-CSRF-Token'] = csrfToken
      }

      return headers
    },
  },
  methods: {
    handleSuccess(file, response) {
      $(file.previewElement).find(".progress-bar").removeClass("progress-bar-striped").addClass("bg-success")

      const $fields = $(response.fields).hide().fadeIn(1000)

      this.targetElement
        .append($fields)
        .trigger("cocoon:after-insert")

      $(file.previewElement).delay(1000).slideUp(1000, () => {
        this.$refs.dropzone.removeFile(file)
      })

    },
    handleError(file, message, xhr) {
      const $errorMessage = $("<span>").addClass("text-danger").text(this.translations.upload_failed)

      $(file.previewElement).find(".progress-bar").removeClass("progress-bar-striped").addClass("bg-danger")
      $(file.previewElement).find(".progress").after($errorMessage)
    },
    handleThumbnail(file, dataUrl) {
      var j, len, ref, thumbnailElement;
      if (file.previewElement) {
          file.previewElement.classList.remove("dz-file-preview");
          ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-img]");
          for (j = 0, len = ref.length; j < len; j++) {
              thumbnailElement = ref[j];
              thumbnailElement.alt = file.name;
              thumbnailElement.style.backgroundImage = `url(${dataUrl})`
          }
          return setTimeout(((function(_this) {
              return function() {
                  return file.previewElement.classList.add("dz-image-preview");
              };
          })(this)), 1);
      }
    }
  }
}

</script>

<style lang='scss'>

.vue-dz-drop-area {
  cursor: pointer;
}

.vue-dz-preview-image {
  width: 4rem;
  height: 4rem;
  background: {
    repeat: no-repeat;
    size: contain;
    position: center center;
  }
}



</style>