const dayInMillis = 24 * 60 * 60 * 1000
const dueWarningInterval = 3 * dayInMillis

function cssClassesForDueDate(date) {
  const now = new Date();
  let danger = false;
  let warning = false
  if (now > date) {
    danger = true
  } else if (now > date - dueWarningInterval) {
    warning = true
  }

  return [["text-danger", danger], ["text-warning", warning]]
}

function updateElement($el) {
  const date = Date.parse($el.data("date"))

  let cssClasses = cssClassesForDueDate(date)

  cssClasses.forEach(function(item){
    let [ cssClass, active ] = item

    $el.toggleClass(cssClass, active)
  })
}

export function start() {
  $("[data-behaviour=due-date]").each(function(){
    let $this = $(this)

    updateElement($this)
  })
}