<template>
  <div class="custom-control custom-switch">
    <input type="checkbox" class="custom-control-input" :id="inputId" :checked='value ? "checked" : null' @input='handleInput'>
    <label class="custom-control-label" :for="inputId">{{ label }}</label>
  </div>
</template>

<script>

export default {
  props: [ "value", "label" ],
  computed: {
    inputId() { return `vue-switch-${this._uid}` },
  },
  methods: {
    handleInput(event) {
      this.$emit("input", !this.value)
    }
  },
}

</script>