<template>
  <div>
    <div v-if='showSpinner' class='d-flex justify-content-center'>
      <Spinner></Spinner>
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>

<script>

import Spinner from "../bootstrap/spinner"

export default {
  components: { Spinner },
  props: {
    delay: {
      default: 400
    },
    loading: {
      type: Boolean,
    }
  },
  data() {
    return {
      showSpinner: false,
      timeout: undefined,
    }
  },
  watch: {
    loading() { this.handleLoadingChanged() }
  },
  mounted() {
    this.handleLoadingChanged()
  },
  methods: {
    handleLoadingChanged() {
      if (this.loading) {
        this.startDelayTimer()
      } else {
        this.showSpinner = false
        this.stopDelayTimer()
      }
    },
    startDelayTimer() {
      if (this.delay && this.delay > 0) {
        this.timeout = setTimeout(() => this.delayTriggered(), this.delay)
      } else {
        this.delayTriggered()
      }
    },
    delayTriggered() {
      this.showSpinner = true
      this._clearDelayTimeout()
    },
    stopDelayTimer() {
      this._clearDelayTimeout()
    },

    _clearDelayTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = undefined
      }
    },
  }
}

</script>