<template>
  <li :class='liClasses'>
    <a href='#' @click.prevent.stop='$emit("click")' :class='linkClasses'>
      <slot />
    </a>
  </li>
</template>

<script>

export default {
  props: {
    liClass: {
      type: String,
      default: ""
    },
    linkClass: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    liClasses() {
      let classes = ["nav-item"]

      if (this.liClass) {
        classes.push(this.liClass)
      }

      return classes;
    },
    linkClasses() {
      let classes = ["nav-link"]

      if (this.linkClass) {
        classes.push(this.linkClass)
      }

      if (this.active) {
        classes.push("active")
      }


      return classes;
    }
  }
}

</script>