import { Controller } from "stimulus"

export default class LogbookEntryFormController extends Controller {
  static targets = [ "departureAt", "departureMileage", "arrivalAt", "arrivalMileage" ]

  get departureMileageValue() { return parseInt(this.departureMileageTarget.value) }
  set departureMileageValue(value) { this.departureMileageTarget.value = value }

  get arrivalMileageValue() { return parseInt(this.arrivalMileageTarget.value) }
  set arrivalMileageValue(value) { this.arrivalMileageTarget.value = value }

  get $departureAt() { return $(this.departureAtTarget) }
  get departureAt() { return this.$departureAt.val() }
  set departureAt(value) { this.$departureAt.val(value).trigger("change") }

  get $arrivalAt() { return $(this.arrivalAtTarget) }
  get arrivalAt() { return this.$arrivalAt.val() }
  set arrivalAt(value) { this.$arrivalAt.val(value).trigger("change") }

  connect() {
    this.$arrivalAt.on("vue-focus", this.arrivalAtFocused.bind(this))
  }

  // ===========
  // = Actions =
  // ===========

  arrivalAtFocused() {
    if (this.arrivalAt == "") {
      console.log("Updating arrival at with departure at", this.departureAt)
      this.arrivalAt = this.departureAt
    }
  }

  arrivalMileageFocused() {
    if (isNaN(this.arrivalMileageValue)) {
      this.arrivalMileageValue = this.departureMileageValue
    }
  }

  // ===========
  // = Private =
  // ===========

}
