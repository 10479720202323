<template>
  <popper :show='calendarVisible'>
    <datetime-input
      :inputId='inputId'
      :value='date'
      :calenderVisible='calendarVisible'
      :format='displayFormat'
      :inputClass='inputClass'
      @focus='triggerInputEvent("focus")'
      @blur='triggerInputEvent("blur")'
      @input='handleValueChanged($event)'
      @toggleCalendar='handleToggleCalendar()'
    />

    <panel
      ref='panel'
      slot='poppable'
      :value='date'
      :minMoment='minMoment'
      :maxMoment='maxMoment'
      :datepanel='datepicker'
      :timepanel='timepicker'
      :nowTitle='nowButtonTitle'
      :closeTitle='closeButtonTitle'
      @input='handleValueChanged($event)'
      @clear='handleClearClicked'
      @now='handleNowClicked'
      @close='handleCloseClicked'
      >
    </panel>
  </popper>
</template>

<script>

import moment from 'moment'

import { Popper } from "../utils"
import DatetimeInput from "./datetime-input"
import Panel from "./panel"

export default {
  props: ['value', 'inputClass', 'inputId', 'nowButtonTitle', 'closeButtonTitle', 'datepicker', 'timepicker', 'minMoment', 'maxMoment'],
  components: { Popper, DatetimeInput, Panel },
  data() {
    return {
      date: null,
      calendarVisible: false,
    }
  },
  computed: {
    displayFormat() {
      const parts = []
      const localeData = moment.localeData()
      if (this.datepicker) {
        parts.push(localeData.longDateFormat('L'))
      }

      if (this.timepicker) {
        parts.push(localeData.longDateFormat('LT'))
      }

      return parts.join(" ")
    },
  },
  watch: {
    value: {
      handler: 'parseDate',
      immediate: true
    }
  },
  methods: {
    parseDate() {
      this.date = this.value ? moment(this.value) : null
    },
    handleValueChanged(value) {
      this.date = this.applyDateRange(value)
      this.$emit("input", value ? this.date.format() : null)
    },
    handleToggleCalendar() { this.calendarVisible = !this.calendarVisible },
    handleNowClicked() {
      this.date = moment()
      this.calendarVisible = false
    },
    handleClearClicked() { this.date = null },
    handleCloseClicked() { this.calendarVisible = false },

    applyDateRange(aMoment) {
      if (aMoment) {
        let momentWithinRange = moment(aMoment)

        if (this.minMoment && momentWithinRange.isBefore(this.minMoment)) {
          momentWithinRange = moment(this.minMoment)
        }

        if (this.maxMoment && momentWithinRange.isAfter(this.maxMoment)) {
          momentWithinRange = moment(this.maxMoment)
        }

        return momentWithinRange
      }
    },

    triggerInputEvent(named) {
      this.$emit(named)
    }
  }
}

</script>