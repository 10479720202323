<template>
  <div class='d-flex justify-content-between'>
    <div>
      <a href='#' class='btn btn-sm btn-secondary' @click.prevent.stop='$emit("prevYear")'><fa-icon icon='backward' /></a>
      <a href='#' class='btn btn-sm btn-secondary' @click.prevent.stop='$emit("prevMonth")'><fa-icon icon='step-backward' /></a>
    </div>
    <div><h4>{{ monthName }} {{ year }}</h4></div>
    <div>
      <a href='#' class='btn btn-sm btn-secondary' @click.prevent.stop='$emit("nextMonth")'><fa-icon icon='step-forward' /></a>
      <a href='#' class='btn btn-sm btn-secondary' @click.prevent.stop='$emit("nextYear")'><fa-icon icon='forward' /></a>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  props: ['currentMoment'],
  computed: {
    monthName() { return this.currentMoment.format("MMM") },
    year() { return this.currentMoment.year() }
  }
}

</script>