<template>
  <div>
    <strong>{{ name }}</strong>
    <small
      v-if='description'
      :class='["d-block", { "text-white": active, "text-muted": !active }]'
      >
      {{ description }}
    </small>
  </div>
</template>

<script>

export default {
  props: {
    name: {
      required: true,
      type: String,
    },
    description: {
      required: false,
      type: String,
    },
    active: {
      required: false,
      default: false,
      type: Boolean
    }
  }
}

</script>