import Vue from 'vue'
import { gmapApi } from 'vue2-google-maps'
import { createSettingsMixin } from '../settings'
import { defaultMapSettings } from '../config'

export function sanitizeCoordinateArray(coordinates) {
  const firstCoordinate = coordinates[0]
  const lastCoordinate = coordinates[coordinates.length - 1]

  if (firstCoordinate[0] == lastCoordinate[0] && firstCoordinate[1] == lastCoordinate[1]) {
    return coordinates.slice(0, coordinates.length - 1)
  } else {
    return coordinates
  }
}

export function buildLatLngFromCoordinates(coordinates) {
  return coordinates.map((coordinate) => buildLatLngFromCoordinate(coordinate))
}

export function buildLatLngFromCoordinate(coordinate) {
  return {lat: coordinate[1], lng: coordinate[0]}
}

export function buildCoordinatesFormLatLngs(latlngs) {
  return latlngs.map((latlng) => [latlng.lng, latlng.lat]) // sic!
}

export function calculateLatLngWithLargestLatitude(latlngs) {
  let max_lat = -90
  let max_point = null

  latlngs.forEach((point) => {
    if (point.lat > max_lat) {
      max_point = point
      max_lat = point.lat
    }
  })

  return max_point;
}


export function extendBoundsWithAreas(bounds, areas) {
  areas.forEach((area) => {
    extendBoundsWithArea(bounds, area)
  })
}

export function extendBoundsWithArea(bounds, area) {
  area.geometry.paths.forEach((path) => {
    path.forEach((latlng) => {
      bounds.extend(latlng)
    })
  })
}


export function extractLiteralFromLatLng(latLng) {
  const lat = latLng.lat()
  const lng = latLng.lng()

  return {lat, lng}
}

export function createMapSettingsMixin() {
  return createSettingsMixin("map", defaultMapSettings)
}