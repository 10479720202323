<template>
  <ul class='list-unstyled colors'>
    <li
      v-for='aColor in colors'
      :key='aColor'
      class='color'
      @click.prevent.stop='$emit("select", aColor)'>
      <span
        class='square rounded shadow-sm'
        :style='{ backgroundColor: aColor }'
        />
    </li>
  </ul>
</template>

<script>

export default {
  props: [ "colors" ],
}

</script>


<style scoped lang='scss'>

.colors {
  --size: 2.5rem;

  display: grid;
  grid-gap: 0.3rem;
  width: 100%;
  grid-template-columns: repeat(auto-fill, var(--size));
}

.square {
  display: block;

  width: var(--size);
  height: var(--size);

  cursor: pointer;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

</style>