<template>
  <div
    ref='dropdown'
    :class='[
      "bg-white",
      "dropdown-container",
    ]'
    :style='{width: `${containerWidth}px`}'
    @mouseenter.stop='$emit("update:hovering", true)'
    @mouseleave.stop='$emit("update:hovering", false)'>
    <slot />
  </div>
</template>

<script>

import Popper from 'popper'

export default {
  props: {
    target: {
      required: true
    },
    hovering: {
      sync: true
    },
  },
  data() {
    return {
      popper: null,
      containerWidth: 0,
    }
  },
  watch: {
    target: {
      immediate: true,
      handler(){
        this.$nextTick(() => this.updateContainerWidth())
      }
    }
  },

  mounted() {
    this.updateContainerWidth()

    this.$nextTick(() => {
      this.initPopper()
    })
  },
  beforeDestroy() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  },
  methods: {
    initPopper() {
      if (!this.target || !this.$refs.dropdown) { return }

      this.$nextTick(() => {
        this.popper = new Popper(this.target, this.$refs.dropdown, {
          placement: "bottom",
          onUpdate: () => this.updateContainerWidth()
        })
      })
    },

    updateContainerWidth() {
      this.$nextTick(() => {
        this.containerWidth = $(this.target).outerWidth()
      })
    }
  }
}

</script>

<style lang='scss' scoped>

.dropdown-container {
  z-index: 1001;
  position: absolute;
}
</style>