<template>
  <span>
    <fa-icon :icon='iconWithScope'></fa-icon>
    <span class='fa5-text' v-if='text'>{{ text }}</span>
  </span>
</template>

<script>

export default {
  props: ["icon", "iconScope", "className", "text", "translations"],
  computed: {
    iconWithScope() {
      return [this.iconScope, this.icon]
    }
  }
}

</script>