<template>
  <div class='date-grid'>
    <grid-header
      v-for='weekday in weekdays'
      :key='weekday'
      :header='weekday'
      />

    <grid-item
      v-for='data, idx in dates'
      :key='`item-${idx}`'
      :date='data.date'
      :active='data.active'
      :today='isToday(data.date)'
      :selected='isSelected(data.date)'
      :disabled='!isWithinRange(data.date)'
      @selected='handleDateSelected(data.date)'
      />
  </div>
</template>

<script>

import moment from 'moment'

import { GridHeader, GridItem } from "./date-panel/index"

function buildDatesInRange(from, to) {
  const dates = []
  let currentDate = moment(from)

  while (currentDate.isBefore(to)) {
    dates.push(moment(currentDate))
    currentDate.add(1, "day");
  }

  return dates
}

export default {
  props: ['currentMoment', 'minMoment', 'maxMoment'],
  components: { GridHeader, GridItem },
  computed: {
    weekdays()        { return moment.weekdaysShort(true) },
    today()           { return moment() },
    startOfMonth()    { return moment(this.currentMoment).startOf("month") },
    endOfMonth()      { return moment(this.currentMoment).endOf("month") },

    startMoment()     { return moment(this.startOfMonth).startOf("week") },
    endMoment()       { return moment(this.endOfMonth).endOf("week") },

    datesUntilStart() { return buildDatesInRange(this.startMoment, this.startOfMonth) },
    datesOfMonth()    { return buildDatesInRange(this.startOfMonth, this.endOfMonth) },
    datesUntilEnd()   { return buildDatesInRange(moment(this.endOfMonth).add(1, "day"), moment(this.endMoment).add(1, "day")) },

    dates() {
      const startDates = this.datesUntilStart.map((date) => { return {active: false, date} } )
      const monthDates = this.datesOfMonth.map((date) => { return {active: true, date} } )
      const endDates = this.datesUntilEnd.map((date) => { return {active: false, date} } )

      return [...startDates, ...monthDates, ...endDates]
    }
  },
  methods: {
    // ===========
    // = Queries =
    // ===========
    isSelected(aMoment) { return aMoment.isSame(this.currentMoment, "day") },
    isToday(aMoment) { return aMoment.isSame(this.today, "day") },
    isWithinRange(aMoment) { return (!this.minMoment || !aMoment.isBefore(this.minMoment)) && (!this.maxMoment || !aMoment.isAfter(this.maxMoment)) },

    // ==================
    // = Event Handling =
    // ==================
    handleDateSelected(date) {
      const newMoment = moment(this.currentMoment).year(date.year()).month(date.month()).date(date.date())

      this.$emit("input", newMoment)
    },
  }
}

</script>

<style scoped>

.date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: min-content;
  grid-gap: 0.3rem;
}

</style>