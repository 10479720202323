
// Finds elements within a parent
// "^.parent-selector >> .child-selector
function findTargetWithinParent(selector, $handle) {
  const [ parentSelector, childSelector ] = selector.split(" >> ")

  return $handle.closest(parentSelector.substring(1)).find(childSelector)
}


function findTargetById(id) {
  return $(document).find(`#${id}`)
}

export function findTarget(selector, handle) {
  const $handleEl = $(handle ? handle : document)

  if (selector.startsWith("^")) {
    return findTargetWithinParent(selector, $handleEl)
  } else {
    return findTargetById(selector)
  }
}