<template>
  <div>
    <GmapMarker
      :position='center'
      :options='markerOptions'
    />
    <GmapCircle
      :center='center'
      :radius='radius'
      :options='circleOptions'
      />
  </div>
</template>

<script>

import AppConfig from 'app-config'
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'

export default {
  props: [ 'position' ],
  computed: {
    center() { return {lat: this.position.coords.latitude, lng: this.position.coords.longitude} },
    radius() { return this.position.coords.accuracy / 2 },
    circleOptions() {
      const circleColor = '#77F'

      return {
        strokeColor: circleColor,
        strokeOpacity: 0.7,
        strokeWeight: 2,
        fillColor: circleColor,
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
      }
    },

    markerSize() { return 10 },
    iconPath() {
      const radius = this.markerSize
      const diameter = radius * 2

      return `m -${radius}, 0 a ${radius},${radius} 0 1,0 ${diameter},0 a ${radius},${radius} 0 1,0 -${diameter},0`
    },

    markerOptions() {
      return {
        icon: {
          path: this.iconPath,
          fillColor: '#44f',
          fillOpacity: 1,
          strokeColor: '#fff',
          strokeWeight: this.markerSize / 2.5
        },
        clickable: false,
        draggable: false,
      }
    }
  }
}

</script>