<template>
  <GmapInfoWindow
    :opened='!!area'
    :position='infoWindowPosition'
    @closeclick='handleCloseClick'>
    <div v-if='area'>
      <h6 class='mb-0'>{{area.title}}</h6>
      <p class='mb-0'>{{area.identifier}}</p>
      <hr class='my-2' />
      <ul class='list-unstyled' v-if='area.tag_line'>
        <li>
          <fa-icon icon='tag'></fa-icon>
          <span v-html='area.tag_line' />
        </li>
      </ul>

      <p>{{ area.human_coordinates }}</p>

      <div v-html='area.rendered_address'></div>

      <div><a :href='area.path' class='btn btn-sm btn-secondary'>{{translations.view}}</a></div>
    </div>
  </GmapInfoWindow>
</template>

<script>

import { calculateLatLngWithLargestLatitude } from "../../../utils/map_utils"


export default {
  props: ['area', 'translations'],
  computed: {
    infoWindowPosition(){
      if (!this.area) {
        return null;
      }

      return calculateLatLngWithLargestLatitude(this.area.geometry.paths[0])
    }
  },
  methods: {
    handleCloseClick() {
      this.$emit("closed")
    }
  }
}

</script>