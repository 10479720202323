<template>
  <a href='#' @click.prevent.stop='toggleFavorite'>
    <fa-icon :icon='icon'></fa-icon>
  </a>
</template>

<script>

import { findTarget } from "../utils/targetable"

export default {
  props: [ "target", "targetInputWrapper" ],
  computed: {
    inputWrapperElement() { return findTarget(this.targetInputWrapper, this.$el) },
    targetElement() { return findTarget(this.target, this.$el) },

    icon() { return this.isFavorite ? "heart" : ["far", "heart"]},
  },
  mounted() {
    this.loadValue()
    this.hideInputWrapper()
  },
  data() {
    return {
      isFavorite: false,
    }
  },
  watch: {
    isFavorite: "updateValue"
  },
  methods: {
    // ==================
    // = Value Handling =
    // ==================
    loadValue() {
      this.isFavorite = this.targetElement.is(":checked")
    },
    updateValue() {
      this.targetElement.prop("checked", this.isFavorite)
    },

    // ==========================
    // = Input Wrapper Handling =
    // ==========================

    hideInputWrapper() {
      this.inputWrapperElement.hide()
    },

    // ============
    // = Toggling =
    // ============

    toggleFavorite() {
      this.isFavorite = !this.isFavorite
    },
  }
}

</script>
