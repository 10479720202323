<template>
  <button
    class='btn btn-sm btn-secondary'
    :disabled='!enabled'
    @click.prevent='$emit("click")'>
    <fa-icon :icon='icon' />
    {{ label }}
  </button>
</template>

<script>

export default {
  props: [ 'label', 'icon', 'enabled' ]
}

</script>