<template>
  <div class='form-group'>
    <label :for='inputId'>{{ translations.label }}</label>
    <select :id='inputId' class='form-control custom-select' @input='$emit("input", $event.target.value)'>
      <option
        v-for='color in colors'
        :key='color'
        :value='color'
        :selected='value == color'
        >
        {{ translations.color[color] }}
      </option>
    </select>
  </div>
</template>

<script>

const colors = ['solid_yellow', 'solid_red', 'access', 'tag'];

export default {
  props: [ 'translations', 'value' ],
  computed: {
    colors() { return colors },
    inputId() { return `vue-color-selector-${this._uid}` },
  },
}

</script>