<template>
  <div class='modal fade'>
    <div :class='["modal-dialog", {"modal-dialog-centered": verticallyCentered}]' role="document">

      <div :class='["modal-content"]'>
        <div class="modal-header" v-if='hasHeader'>
          <h5 class="modal-title">{{ title }}</h5>


          <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-if='closeButton'>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class='modal-body'>
          <slot />
        </div>

        <div class="modal-footer" v-if='$slots.footer'>
          <slot name='footer' />
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    verticallyCentered: {
      default: true,
      type: Boolean,
    },
    closeButton: {
      default: true,
      type: Boolean,
    }
  },
  updated() {
    this.jqueryElement.modal("handleUpdate")
  },
  computed: {
    jqueryElement() { return $(this.$el) },
    hasHeader() { return this.$slots.header || this.closeButton },
  },
  methods: {
    open() { this.jqueryElement.modal('show') },
    close() { this.jqueryElement.modal('hide') },
  }
}

</script>