<template>
  <div class='d-flex align-items-center'>
    <custom-select v-model='currentHour'>
      <option
        v-for='hour in hours'
        :key='hour'
        :value='hour'
        >
        {{ formatNumber(hour) }}
      </option>
    </custom-select>
    <span class='mx-1'>:</span>
    <custom-select v-model='currentMinute'>
      <option
        v-for='minute in minutes'
        :key='minute'
        :value='minute'
        >
        {{ formatNumber(minute) }}
      </option>
    </custom-select>
  </div>
</template>

<script>

import moment from 'moment'

import { CustomSelect } from "../bootstrap"

function range(from, to) {
  const items = []

  for (let i = from; i <= to; i++) {
    items.push(i)
  }

  return items
}

export default {
  props: ['currentMoment'],
  components: { CustomSelect },
  data() {
    return {
      currentHour: 0,
      currentMinute: 0,
    }
  },
  computed: {
    hours() { return range(0, 23) },
    minutes() { return range(0, 59) },
  },
  watch: {
    currentMoment: {
      handler: "updateParts",
      immediate: true,
    },
    currentHour: "triggerUpdate",
    currentMinute: "triggerUpdate",
  },
  methods: {
    updateParts() {
      this.currentHour = this.currentMoment.hour()
      this.currentMinute = this.currentMoment.minute()
    },
    formatNumber(number) {
      return (number < 10) ? "0" + number : number;
    },

    triggerUpdate() {
      const newMoment = moment(this.currentMoment).hour(this.currentHour).minute(this.currentMinute)

      if (!this.currentMoment.isSame(newMoment)) {
        this.$emit("input", newMoment)
      }
    }
  }
}

</script>