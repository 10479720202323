import StatusWatcher from "./status_watcher"

export function start() {
  let currentWatchers = []

  $(document).on("turbolinks:load", function() {
    currentWatchers.forEach((watcher) => watcher.stop())
    currentWatchers = []

    $("[data-behaviour=status-watcher]").each(function() {
      const $element = $(this)
      const watcher = new StatusWatcher.from($element)

      currentWatchers.push(watcher)
    });
  })
}