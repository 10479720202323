import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

import { googleAPIKey as apiKey } from "../config"

export function install() {
  Vue.use(VueGoogleMaps, {
    load: {
      key: apiKey,
      libraries: 'places'
    }
  })
}