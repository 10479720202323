import { Controller } from 'stimulus'
import { stop as stopEvent } from "../../utils/events"


export default class extends Controller {
  static targets = ["modal", "content"]

  get defaultURL() { this.data.get("url") }

  get isLoading() { return this.data.get("loading") == "true" }
  set isLoading(value) { this.data.set("loading", !!value) }

  get remoteContentController() { return this.application.getControllerForElementAndIdentifier(this.contentTarget, "remote-content") }


  show(e) {
    stopEvent(e)

    const url = this._getURLFromTargetOrDefault(e)

    this._load(url)
    this._showModal()
  }

  hide() {
    this._hideModal()
  }


  // ==================
  // = Modal Handling =
  // ==================

  _showModal() {
    $(this.modalTarget).modal("show")
  }

  _hideModal() {
    $(this.modalTarget).modal("hide")
  }

  // ===================
  // = Content Loading =
  // ===================

  _load(url) {
    this.remoteContentController.load(url)
  }

  // ===========
  // = Helpers =
  // ===========

  _getURLFromTargetOrDefault(e) {
    const { target } = e
    const urlTarget = target.closest("[href], *[data-remote-modal-url]")

    return urlTarget ? urlTarget.getAttribute("href") ||urlTarget.dataset.remoteModalUrl : this.defaultURL
  }

}