import ContactController from "./contact_controller"
import PropertyController from "./property_controller"

function buildControllerWithElement($element) {
  switch ($element.data("controller")) {
  case "contact":
    return new ContactController($element)
  case "property":
    return new PropertyController($element)
  }
}


export default function start() {
  let currentModals = []

  $(document).on("turbolinks:load", function() {
    currentModals.forEach((modal) => modal.destroy())
    currentModals = []

    $('*[data-behaviour=selectable-modal]').each(function() {
      const $element = $(this)

      const controller = buildControllerWithElement($element)
      currentModals.push(controller)
    })
  })
}