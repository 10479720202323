<template>
  <div>
    <div ref='anchor'>
      <slot />
    </div>
    <div ref='popover' class='popover-container' :style='{minWidth: `${containerWidth}px`}' v-show='show'>
      <slot name='poppable'/>
    </div>
  </div>
</template>

<script>

import Popper from 'popper'

export default {
  props: ['show'],
  data() {
    return {
      containerWidth: 0,
      popper: null,
    }
  },
  watch: {
    show: {
      handler: "handlePopperLifecycle",
      immediate: true
    },
    containerWidth: "updatePopper",
  },
  mounted() {
    this.$nextTick(() => this.updateContainerWidth())
  },
  methods: {
    handlePopperLifecycle() {
      if (this.show && !this.popprt) {
        this.createPopper()
      } else if (!this.show && this.popprt) {
        this.removePopper()
      }
    },

    createPopper() {
      this.popper = new Popper(this.$refs.anchor, this.$refs.popover, {
        placement: "bottom",
        arrow: true,
        onUpdate: () => this.updateContainerWidth()
      })
      this.updateContainerWidth()
    },

    removePopper() {
      this.popper.destroy()
      this.popper = null
    },

    updatePopper() {
      if (this.popper) {
        this.popper.scheduleUpdate()
      }
    },

    updateContainerWidth() {
      this.containerWidth = $(this.$refs.anchor).outerWidth()
    }
  }
}
</script>

<style scoped>

.popover-container {
  z-index: 1001;
}

</style>