import Vue from 'vue'

const components = loadComponentsFromContext(require.context("../root", true, /\.vue$/))

function loadComponentsFromContext(context) {
  const components = {}

  context.keys().forEach((key) => {
    const identifier = identifierForKey(key)

    const module = context(key)
    components[identifier] = module.default
  })

  return components
}

function identifierForKey(key) {
  return key.replace(/^.*\//g, "").replace(/\.vue$/, "")
}


function extractComponentData($element) {
  return $element.data("props")
}

export function createVueInstance(el, componentName, props) {
  const component = components[componentName];

  if (!component) {
    console.error(`Could not find top-level component named ${componentName}`)
    return
  }

  console.log(`Creating ${componentName} with`, props)

  const options = {
    components,
    el,
    render: (h) => h(componentName, {
      attrs: {...props}
    })
  };

  return new Vue(options)
}