<template>
  <div>
    <div :class='["form-group", {"form-group-invalid": hasError, "is-invalid": hasError}]'>
      <label v-if='label' :for='inputIdentifier'>{{ label }}</label>

      <div :class='["input-group", "mb-3", {"is-invalid": hasError}]'>
        <div class="input-group-prepend">
          <span class="input-group-text preview-field" :style='previewFieldStyle'></span>
        </div>
        <input :class='["form-control", {"is-invalid": hasError}]' type='text' :id='inputIdentifier' :placeholder='placeholder' v-model='color' />
        <div class='invalid-feedback' v-if='error'>{{ error }}</div>
      </div>
      <p class='text-muted mb-1'>{{ translations.pick_color_hint }}</p>
      <div class='row'>
        <div class='col-12 col-md-6' v-if='hasRecentColors'>
          <small class='text-muted'>{{ translations.recent }}</small>
          <ListOfColors :colors='recentColors' @select='handleColorSelected($event)'></ListOfColors>
        </div>
        <div class='col-12 col-md-6'>
          <small class='text-muted'>{{ translations.default }}</small>
          <ListOfColors :colors='defaultColors' @select='handleColorSelected($event)'></ListOfColors>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ListOfColors } from "../components/color-picker"

const defaultColors = ["red", "green", "blue", "aquamarine", "yellow"]

export default {
  props: [ 'target', 'translations', 'recentColors' ],
  components: { ListOfColors },
  data() {
    return {
      color: "",
      error: "",
      label: null,
      placeholder: null,
    }
  },
  computed: {
    hasError() { return !!this.error },
    targetElement() { return $(this.target) },
    targetFormGroup() { return this.targetElement.closest(".form-group") },
    inputIdentifier() { return `vue-color-picker-${this._uid}` },
    defaultColors() { return defaultColors },
    hasRecentColors() { return this.recentColors && this.recentColors.length },
    previewFieldStyle() {
      return {
        backgroundColor: this.color,
      }
    }
  },
  mounted() {
    this.extractFormData()
    this.hideFormGroup()
  },
  destroyed() {
    this.showFormGroup()
  },
  watch: {
    color() {
      this.targetElement.val(this.color)
    }
  },
  methods: {
    extractFormData() {
      this.color = this.targetElement.val()
      this.error = this.targetFormGroup.find(".invalid-feedback").text()
      this.label = this.targetFormGroup.find("label").text()
      this.placeholder = this.targetElement.attr("placeholder")
    },

    // =======================
    // = Form Group Handling =
    // =======================
    hideFormGroup() {
      this.targetFormGroup.hide()
    },

    showFormGroup() {
      this.targetFormGroup.show()
    },

    // ==================
    // = Event Handling =
    // ==================
    handleColorSelected(color) {
      this.color = color
    },
  }
}
</script>

<style scoped lang='scss'>

.preview-field {
  width: 4rem;
}

</style>