<template>
  <div class='map-container bg-light'>
    <GmapMap
      ref='map'
      style="width: 100%; height: 100%"
      :center='mapCenter'
      :zoom='9'
      :options='googleMapsOptions'
      map-type-id='hybrid'
      @click='handleMapClick($event)'>

      <div v-if='showMarkers'>
        <GmapMarker
          v-for='(latlng, i) in path'
          :key='i'
          :position='latlng'
          :draggable='true'
          @dragend='handleMarkerDragend(i, $event)'
          @dblclick='handleMarkerDblClick(i, $event)'>
        </GmapMarker>
      </div>
      <div v-else>
        <GmapPolygon
          :paths='[path]'
          :editable='true'
          :options='polygonStyle'
          @paths_changed='handlePolygonPathsChanged($event)'
          @click='handleMapClick($event)'
          @dblclick='handlePolygonDblClick($event)'>
        </GmapPolygon>
      </div>
    </GmapMap>
  </div>

</template>

<script>

import { gmapApi } from 'vue2-google-maps'

import { buildLatLngFromCoordinates, buildCoordinatesFormLatLngs, extractLiteralFromLatLng } from "../../utils/map_utils"
import { defaultMapPosition, defaultMapOptions } from "../../config"
import { AltButtonListener } from "./lib"

const polygonStyle = {
  fillColor: "red",
  strokeColor: "red",
}

export default {
  props: [ 'value' ],

  mounted() {
    this.altButtonListener.start()
    this.altButtonListener.onAltChanged = (pressed) => this.altPressed = pressed
  },
  beforeDestroy() {
    this.altButtonListener.stop()
  },
  computed: {
    google: gmapApi,
    mapCenter() { return defaultMapPosition },
    polygonStyle() { return polygonStyle },
    googleMapsOptions(){ return { draggableCursor: (this.altPressed ? "crosshair" : ""), ...defaultMapOptions } },

    showMarkers() { return this.path.length < 3 },
    isPolygon() { return this.path.length >= 3},
    pathBounds() {
      if (!this.isPolygon) return null

      const bounds = new this.google.maps.LatLngBounds()
      this.path.forEach((latlng) => bounds.extend(latlng))

      return bounds
    }
  },
  data() {
    const altButtonListener = new AltButtonListener()

    return {
      altButtonListener,
      altPressed: false,
      path: [],
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: "loadValue"
    },
    path: "emitInputEvent",
  },
  methods: {
    // ==============
    // = Public API =
    // ==============

    focusPath() {
      this.withMap((map) => {
        if (this.pathBounds) {
          map.fitBounds(this.pathBounds)
        }
      })
    },

    focusPlace(place) {
      this.withMap((map) => map.fitBounds(place.geometry.viewport))
    },

    // ==================
    // = Value Handling =
    // ==================
    loadValue() { this.path = this.value },
    emitInputEvent() { this.$emit("input", this.path) },

    // ==================
    // = Event Handling =
    // ==================
    handleMapClick(e) {
      if (this.altPressed){
        const latlng = extractLiteralFromLatLng(e.latLng)

        this.path.push(latlng)
      }
    },

    handlePolygonDblClick(e) {
      const vertexIdx = e.vertex

      if (vertexIdx !== undefined) {
        this.path.splice(vertexIdx, 1)
      }
    },

    handlePolygonPathsChanged(mvcPaths) {
      const mvcPath = mvcPaths.getAt(0);

      let points = []
      mvcPath.forEach((latLng) => {
        points.push(extractLiteralFromLatLng(latLng))
      })

      this.path = points;
    },

    handleMarkerDblClick(idx, e) {
      this.path.splice(idx, 1);
    },

    handleMarkerDragend(idx, e) {
      const latlng = extractLiteralFromLatLng(e.latLng)

      this.path[idx] = latlng
    },

    // ==================
    // = Helper Methods =
    // ==================

    withMap(callback) { this.$refs.map.$mapPromise.then(callback) },
  },
}

</script>