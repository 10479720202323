// Using vendored version because npm package depends on jQuery
// which would be loaded by the browser twice

import { getLocale } from "../utils"
import locales from "./locales"

function getTimeagoLocale() {
  const currentLocale = getLocale()

  let locale = locales[currentLocale]
  if (!locale) {
    console.error(`Could not find locale "${currentLocale}" in timeago locales. Defaulting to "en".`)

    locale = locales.en
  }

  return locale
}

function setCurrentLocale() {
  jQuery.timeago.settings.strings = getTimeagoLocale()
}

function updateSettings() {
  jQuery.timeago.settings.cutoff = 1000 * 60 * 60 * 24 * 7; // Display original date after 1 week
  jQuery.timeago.settings.allowFuture = true
  jQuery.timeago.settings.localeTitle = true
}

export function start() {
  $(document).on("turbolinks:load", function() {
    setCurrentLocale()
    updateSettings()
  })
}