<template>
  <div :class="classNames" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: "border"
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    baseName() {
      switch (this.type) {
        case "grow":
          return "grow"
        default:
          return "border"
      }
    },
    classNames() {
      let classNames = [ `spinner-${this.baseName}` ]

      if (this.small) {
        classNames.push(`spinner-${this.baseName}-sm`)
      }

      return classNames;
    }
  }
}

</script>