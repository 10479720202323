const EVENT_SCOPE = 'alt-button-listener'

let id = 0
export default class AltButtonListener {
  constructor() {
    this.id = id++;
    this.altPressed = false
    this.onAltChanged = () => console.warn("Please set onAltChanged to listen for presses to alt button")
  }

  start() {
    const handleEvent = this._handleEvent.bind(this)

    $(document).on(this._scopedEvent("keydown"), handleEvent)
    $(document).on(this._scopedEvent("keyup"), handleEvent)
  }

  stop() {
    $(document).off(this._scopedEvent("keydown"))
    $(document).off(this._scopedEvent("keyup"))
  }

  _handleEvent(e) {
    const altNowPressed = e.originalEvent.altKey

    if (this.altPressed != altNowPressed) {
      this.altPressed = altNowPressed
      this.onAltChanged(this.altPressed)
    }
  }

  _scopedEvent(named) { return `${named}.${EVENT_SCOPE}-${this.id}` }
}