<template>
  <div class='input-group'>
    <input
      type='text'
      :id='inputId'
      :class='["form-control", inputClass]'
      v-model='fieldValue'
      @focus='handleFocus'
      @blur='handleBlur'
       />
    <div class="input-group-append">
      <a
        href='#'
        :class='["btn btn-secondary", {active: calenderVisible}]'
        @click.prevent.stop='$emit("toggleCalendar")'>
        <fa-icon icon='calendar-alt'></fa-icon>
      </a>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  props: ['value', 'calenderVisible', 'format', 'inputClass', 'inputId'],
  data(){
    return {
      fieldValue: null,
      focused: false,
      ignoreNextValueUpdate: false,
    }
  },
  mounted() {
    this.updateFieldValue()
  },
  watch:{
    value: 'updateFieldValue',
    focused: 'updateFieldValue',
    fieldValue: 'handleFieldValueUpdate'
  },
  methods: {
    updateFieldValue() {
      if (!this.ignoreNextValueUpdate) {
        this.fieldValue = this.value ? moment(this.value).format(this.format) : null
      } else {
        this.ignoreNextValueUpdate = false
      }
    },
    handleFieldValueUpdate() {
      // Emitting the "input" event will trigger a round-trip value update, which will
      // invoke updateFieldValue() above. Changing the field value while the user is editing
      // the field is a bad idea (the user might create invalid dates during plain-text editing).
      // Therefore, we ignore the next value update event we receive.
      this.ignoreNextValueUpdate = true

      if (this.fieldValue != ""){
        const fieldMoment = moment(this.fieldValue, this.format)

        if (fieldMoment.isValid()) {
          const fieldDate = fieldMoment.toDate();

          this.$emit("input", fieldDate)
        } else {
          this.$emit("input", null)
        }
      } else {
        this.$emit("input", null)
      }
    },
    handleFocus() {
      this.focused = true
      this.$emit("focus")
    },
    handleBlur() {
      this.focused = false
      this.$emit("blur")
    },
  }
}

</script>
