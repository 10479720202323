<template>
  <div>
    <Toolbar
      class='mb-2'
      :translations='translations.toolbar'
      :settings='settings'
      :size='mapSize'
      :hasUserPosition='hasUserPosition'
      @reset='handleOnResetClicked'
      @placeSelected='handlePlaceSelected'
      @focusMyLocation='handleFocusMyLocation'
    />

    <MapContainer
      ref='map'
      :settings='settings'
      :size='mapSize'>
      <AreaPolygon
        v-for='area in areas'
        :key='`polygon-${area.id}`'
        :area='area'
        :colorize='settings.color'
        @click='handleAreaClicked($event)'>
      </AreaPolygon>
      <AreaPin
        v-if='settings.areaPins'
        v-for='area in areas'
        :area='area'
        :key='`pin-${area.id}`'
        @click='handleAreaClicked($event)'
       />
      <UserLocation
        :position='userPosition'
        v-if='hasUserPosition'
      />
      <AreaInfoWindow
        :area='selectedArea'
        :translations='translations.info_window'
        @closed='handleClearSelection' />
    </MapContainer>

  </div>
</template>

<script>

import { gmapApi } from 'vue2-google-maps'

import { Modal } from "../components/bootstrap"
import { MapContainer } from "../components/maps"

import { Polygon as AreaPolygon, Pin as AreaPin, InfoWindow as AreaInfoWindow, Toolbar } from "../components/maps/area"
import { UserLocation } from "../components/maps"
import { extendBoundsWithAreas, extendBoundsWithArea, createMapSettingsMixin } from "../utils/map_utils"
import { fetchAreas } from "../api"

import { UserPositionManager } from "../../utils"

const mapSettingsMixin = createMapSettingsMixin()

const MAP_SIZES = ["default", "small"]

export default {
  props: {
    url: {},
    focusAreaId: {},
    translations: {},
    size: {
      default: "default",
    }
  },
  mixins: [ mapSettingsMixin ],
  data() {
    return {
      areas: undefined,
      selectedArea: null,
      readyPromise: undefined,
      loading: false,
      error: undefined,
      userPositionManager: new UserPositionManager(),
      userPosition: null,
    }
  },
  mounted() {
    this.setupUserPositionManager()
    this.setupReadyPromise()
  },
  beforeDestroy() {
    this.userPositionManager.stop()
  },
  components: { MapContainer, AreaPolygon, AreaPin, AreaInfoWindow, Toolbar, UserLocation },
  computed: {
    google: gmapApi,
    mapSize() {
      if (MAP_SIZES.includes(this.size)) {
        return this.size
      } else {
        return MAP_SIZES[0]
      }
    },
    areasBounds() {
      let bounds = new this.google.maps.LatLngBounds()

      extendBoundsWithAreas(bounds, this.areas)

      return bounds
    },
    focusArea() {
      let focusArea = null;

      if (this.focusAreaId && this.areas) {
        this.areas.forEach((area) => {
          if (area.id == this.focusAreaId) {
            focusArea = area
          }
        })
      }

      return focusArea;
    },
    focusAreaBounds() {
      if (!this.focusArea) {
        return null;
      }
      let bounds = new this.google.maps.LatLngBounds()

      extendBoundsWithArea(bounds, this.focusArea)

      return bounds
    },
    boundsToApply() { return this.focusAreaBounds || this.areasBounds },

    hasUserPosition() { return this.userPosition != null },
    hasUserPositionError() { return this.userPositionManager.error != null },
    shouldWatchUserLocation() { return this.settings.showUserLocation },
  },
  watch: {
    areas() {
      if (this.focusArea) {
        this.selectedArea = this.focusArea
      }
    },
    shouldWatchUserLocation: {
      immediate: true,
      handler() {
        if (this.shouldWatchUserLocation) {
          this.userPositionManager.start()
        } else {
          this.userPositionManager.stop()
          this.userPosition = null
        }
      },
    },
  },
  methods: {
    setupUserPositionManager() {
      this.userPositionManager.onUpdatePosition = (position) => this.userPosition = position
    },

    setupReadyPromise() {
      this.readyPromise = Promise.all([
        this.$gmapApiPromiseLazy(),
        this.fetchAreasPromise(),
      ]).then(() => {
        this.updateMapFocus()
      })
    },

    fetchAreasPromise() {
      this.loading = true;

      return fetchAreas(this.url)
        .then((areas) =>  this.areas = areas)
        .catch((error) => this.error = error)
        .finally(() =>    this.loading = false)
    },

    updateMapFocus() {
      if (this.boundsToApply) {
        this.$refs.map.fitBounds(this.boundsToApply)
      }
    },

    // ==================
    // = Event Handling =
    // ==================

    handleAreaClicked(area) { this.selectedArea = area },
    handleOnResetClicked() {
      this.updateMapFocus()

      if (this.focusArea) {
        this.selectedArea = this.focusArea
      }
    },
    handleOnSettingsClicked() { this.$refs.settingsModal.open() },
    handlePlaceSelected(place) { this.$refs.map.fitBounds(place.geometry.viewport) },

    handleClearSelection() { this.selectedArea = null },
    handleFocusMyLocation() {
      if (this.hasUserPosition) {
        this.$refs.map.setMapCenter({
          lat: this.userPosition.coords.latitude,
          lng: this.userPosition.coords.longitude,
        })
      } else if (this.hasUserPositionError) {
        alert(`Error: ${this.userPositionManager.error}`)
      }
    },
  }

}

</script>