<template>

  <div class='row align-items-center justify-content-between'>
    <div class='col-auto mt-2'>
      <Button icon='eye' :label='translations.focus' :enabled='enableFocus' @click='$emit("focus-path")' />
    </div>

    <div class='col-md col-12 mt-2'>
      <AutocompleteField @select='$emit("place-selected", $event)' />
    </div>

    <div class='col-auto mt-2'>
      <Button :enabled='enableClear' icon='trash' :label='translations.clear' @click='$emit("clear")' />
      <Button v-if='isResettable' :enabled='enableReset' icon='undo' :label='translations.reset' @click='$emit("reset")' />
    </div>
  </div>
</template>

<script>

import { Button, AutocompleteField } from "./toolbar_components"

export default {
  props: ['translations', 'enableFocus', 'enableClear', 'isResettable', 'enableReset'],
  components: { Button, AutocompleteField },
}

</script>