import { library } from '@fortawesome/fontawesome-svg-core'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

export function setup() {
  library.add(fas)
  library.add(far.faStar)
  library.add(far.faHeart)
  library.add(far.faCircle)
}