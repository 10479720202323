export function disableTurbolinksForHrefsWithAnchorTags() {

  $(document).on("turbolinks:click", (e)=> {
    const $target = $(e.target)

    if ($target.attr("href").indexOf("#") != -1) {
      e.preventDefault()
    }
  })

}