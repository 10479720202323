<template>
  <div class='item'>
    <a
      href='#'
      :class='["btn btn-sm btn-block", buttonClass, {disabled: disabled}]'
      @click.prevent.stop='$emit("selected")'>

      {{day}}

      <span class='today-indicator' >
        <fa-icon icon='caret-right' v-if='today' transform="shrink-0 left-4 rotate-45" />
      </span>
    </a>
  </div>
</template>

<script>

export default {
  props: ['date', 'active', 'today', 'selected', 'disabled'],
  computed: {
    day() { return this.date.date() },

    buttonClass() {
      if (!this.active) {
        return "text-muted"
      } else if (this.selected) {
        return "btn-primary"
      } else {
        return "btn-light"
      }
    }
  }
}

</script>

<style scoped>

.item {
  position: relative;
}

.today-indicator {
  position: absolute;
  display: block;
  bottom: -0.2rem;
  right: 0rem;
}

</style>