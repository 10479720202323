import { start as startReportImagesSortable } from "./report_images"

function startSortables() {
  startReportImagesSortable()
}

export function start() {
  $(document).on("turbolinks:load", () => {
    startSortables()
  })
}