<template>
  <PolygonGeometry v-if='type == "Polygon"' :geometry='geojson' v-on="$listeners" v-bind='$attrs' />
</template>

<script>

import PolygonGeometry from "./polygon"

export default {
  components: { PolygonGeometry },
  props: ["geojson"],
  computed: {
    type() { return this.geojson.type }
  }
}

</script>