export default class UserPositionManager {
  constructor(options) {
    this.options = options || {}
    this.watchId = null
    this.error = null
    this.onUpdatePosition = () => console.warn("Warning: Please set onUpdatePosition() to receive location updates.")
  }

  start() {
    this.error = null

    if ("geolocation" in navigator) {
      const onSuccess = this._onSuccess.bind(this)
      const onError = this._onError.bind(this)

      navigator.geolocation.getCurrentPosition(onSuccess, onError, this.options)
      this.watchId = navigator.geolocation.watchPosition(onSuccess, onError, this.options)
    }
  }

  stop() {
    if (this.watchId !== null) {
      navigator.geolocation.clearWatch(this.watchId)
      this.watchId = null
    }
  }

  // ==================
  // = Event Handlers =
  // ==================

  _onSuccess(position) {
    this.onUpdatePosition(position)
  }

  _onError(error) {
    this.error = error.message
  }
}