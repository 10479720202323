<template>
  <div>
    <Toolbar
      :translations='translations'
      :enable-focus='isPolygon'
      :enable-clear='hasPath'
      :enable-reset='hasChanges'
      :is-resettable='isPathResettable'

      @focus-path='focusPath'
      @clear='clearPath'
      @reset='resetPath'

      @place-selected='handlePlaceSelected'
    />

    <EditorMap
      class='my-2'
      v-model='path'
      ref='map'
      />

    <Hint class='my-2'>{{ translations.hint }} </Hint>

  </div>
</template>

<script>

import { buildLatLngFromCoordinates, buildCoordinatesFormLatLngs, extractLiteralFromLatLng, sanitizeCoordinateArray } from "../utils/map_utils"
import { Toolbar, AltButtonListener, EditorMap, Hint } from "../components/polygon-editor";

export default {
  props: ['target', 'translations'],
  components: { Toolbar, EditorMap, Hint },
  data() {
    return {
      path: [],
      rawOriginalGeoJSON: null,
      originalPath: [],
    }
  },

  mounted() {
    this.loadOriginalGeoJSON()
    this.resetPath()

    this.delayedFocusPath()
    this.installBootstrapTabMonkeyPatch(() => this.delayedFocusPath())
  },

  computed: {
    hasPath() { return this.path.length > 0 },
    hasChanges() {
      if (this.path.length != this.originalPath.length) {
        return true
      }

      for (let i = 0; i < this.path.length; i++) {
        const currentPoint = this.path[i]
        const originalPoint = this.originalPath[i]

        if (currentPoint.lat != originalPoint.lat || currentPoint.lng != originalPoint.lng) {
          return true
        }
      }

      return false;
    },

    isPolygon(){ return this.path.length >= 3 },
    isPathResettable() { return this.originalPath.length > 0 },
  },
  watch:{
    path: "writeGeoJSON"
  },
  methods: {
    // ====================
    // = GeoJSON Handling =
    // ====================
    loadOriginalGeoJSON(){
      const rawJSON = $(this.target).val()

      if (rawJSON && rawJSON != "null") {
        const json = JSON.parse(rawJSON)

        const allCoordinates = json.coordinates
        const mainCoordinates = allCoordinates[0]
        const sanitizedCoordinates = sanitizeCoordinateArray(mainCoordinates)

        this.originalPath = buildLatLngFromCoordinates(sanitizedCoordinates);
      }
    },

    writeGeoJSON() {
      let geoJSON = "null"

      if (this.isPolygon){
        geoJSON = JSON.stringify({ type: "Polygon", coordinates: [buildCoordinatesFormLatLngs(this.path)] })
      }

      $(this.target).val(geoJSON)
    },

    // =================
    // = Path Handling =
    // =================

    focusPath() { this.$refs.map.focusPath() },
    clearPath() { this.path = [] },
    resetPath() { this.path = this.originalPath },

    delayedFocusPath() { this.$nextTick(() => this.focusPath()) },

    // ==================
    // = Place Handling =
    // ==================
    handlePlaceSelected(place) { this.$refs.map.focusPlace(place) },

    // ============================
    // = Bootstrap Monkey Patches =
    // ============================

    installBootstrapTabMonkeyPatch(callback) {
      const pane = $(this.$el).closest(".tab-pane")

      if (pane.length > 0){
        // We are displayed in a hidden bootstrap tab
        const pane_id = pane.attr("id")
        const tab_link = $(`[href='#${pane_id}']`)

        tab_link.one("shown.bs.tab", callback)
      }
    },
  },

}

</script>