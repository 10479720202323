import { Controller } from 'stimulus'

import { stop as stopEvent } from "../../utils/events"

export default class extends Controller {
  static targets = [ "content", "loading", "error" ]
  get isLoading() { return this.data.get("loading") == "true" }
  set isLoading(value) { this.data.set("loading", !!value) }


  // TODO: Maybe add a data attribute?
  get loadingTimerDelay() { return 100 }

  // ===========
  // = Actions =
  // ===========

  navigate(e) {
    stopEvent(e)

    this.load(this._getURLForNavigatable(e.target))
  }

  load(url) {
    this._startLoadingTimer()
    this._fetchContent(url)
  }

  displayJSONContent(e) {
    const { detail } = e
    const json = detail[0]

    this._displayContent(json.remote_content)
  }

  // =======================
  // = View State Handling =
  // =======================

  _resetView() {
    const { contentTarget } = this

    contentTarget.innerHTML = ""

    this._hideElement(this.contentTarget)
    this._hideElement(this.errorTarget)
    this._hideElement(this.loadingTarget)
  }

  _showLoadingContent() {
    this._hideElement(this.contentTarget)
    this._hideElement(this.errorTarget)
    this._showElement(this.loadingTarget)
  }

  _showRemoteContent() {
    this._showElement(this.contentTarget)
    this._hideElement(this.errorTarget)
    this._hideElement(this.loadingTarget)
  }

  _showErrorContent() {
    this._hideElement(this.contentTarget)
    this._showElement(this.errorTarget)
    this._hideElement(this.loadingTarget)
  }

  _hideElement(element) {
    element.classList.add("d-none")
  }

  _showElement(element) {
    element.classList.remove("d-none")
  }

  // ===========================
  // = Remote Content Handling =
  // ===========================

  _fetchContent(url) {
    $.ajax({
      url,
      beforeSend: (xhr) => xhr.setRequestHeader("X-Variant", "remote"),
      success: this._displayContent.bind(this),
      error: this._displayError.bind(this)
    })
  }

  _displayContent(data) {
    this._cancelLoadingTimer()

    if (data) {
      this.contentTarget.innerHTML = data
      this._showRemoteContent()
    } else {
      this._displayError()
    }
  }

  _displayError() {
    this._cancelLoadingTimer()

    this._showErrorContent()
  }

  // =================
  // = Loading Timer =
  // =================

  _startLoadingTimer() {
    this.loadingTimer = setTimeout(()=> {
      this.loadingTimer = null

      this._showLoadingContent()
    }, this.loadingTimerDelay)
  }

  _cancelLoadingTimer() {
    if (this.loadingTimer) {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = null
    }
  }

  // ===========
  // = Helpers =
  // ===========

  _getURLForNavigatable(element) {
    const navigatable = element.closest("*[href], *[data-remote-content-url]")

    return navigatable.getAttribute("href") || element.dataset.remoteContentUrl
  }
}