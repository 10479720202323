const SELECTOR = "[data-behavior=remote-popover-content]"
const PopoverEvents = {
  SHOW: "show.bs.popover",
  HIDE: "hide.bs.popover",
  INSERTED: "inserted.bs.popover",
}

const RemoteEvents = {
  INSERTED: "inserted.remote-popover",
}

const DocumentEvents = {
  KEYDOWN: "keydown.remote-popover"
}

const KeyCodes = {
  ESC: 27,
}

const DATA_KEY = "remote-popover-is-shown"

function getTipElement($triggerElement) {
  const popover = $triggerElement.data("bs.popover")

  return $(popover.getTipElement())
}

function installRemoteLoadingBehavior() {
  $(document).on("ajax:before", SELECTOR, (e) => {
    const $this = $(e.target)
    const shouldLoad = $this.data(DATA_KEY) == "true"

    return shouldLoad
  }).on(PopoverEvents.SHOW, SELECTOR, (e) => {
    const $this = $(e.target)
    $this.data(DATA_KEY, "true")
  }).on(PopoverEvents.HIDE, SELECTOR, (e) => {
    const $this = $(e.target)
    $this.data(DATA_KEY, "false")
  }).on(PopoverEvents.INSERTED, SELECTOR, (e) => {
    const $this = $(e.target)
    const $tip = getTipElement($this)

    $tip.on(RemoteEvents.INSERTED, (e) => {
      $this.popover("update")
      $tip.find("input[autofocus]").focus()
    })
  })
}

function installCloseButtonListener() {
  $(document).on(PopoverEvents.INSERTED, SELECTOR, (e) => {
    const $this = $(e.target)
    const $tip = getTipElement($this)

    $tip.on("click", "[data-behavior=dismiss]", (e) => {
      e.preventDefault()
      e.stopPropagation()

      $this.popover("hide")
    })
  })
}

function installEscKeypressListener() {
  $(document).on(PopoverEvents.SHOW, SELECTOR, (e) => {
    const $this = $(e.target)

    $(document).on(DocumentEvents.KEYDOWN, (e) => {
      if (e.which == KeyCodes.ESC) {
        $this.popover("hide")
      }
    })
  }).on(PopoverEvents.HIDE, SELECTOR, (e) => {
    $(document).off(DocumentEvents.KEYDOWN)
  })
}

export function setup() {
  installRemoteLoadingBehavior()
  installCloseButtonListener()
  installEscKeypressListener()
}