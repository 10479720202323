<template>
  <div class='card p-2 shadow'>
    <panel-header
      :currentMoment='currentMoment'
      @prevYear='prevYear()'
      @prevMonth='prevMonth()'
      @nextMonth='nextMonth()'
      @nextYear='nextYear()'
      />
    <date-panel
      v-if='datepanel'
      :currentMoment='currentMoment'
      :minMoment='minMoment'
      :maxMoment='maxMoment'
      @input='setCurrentMoment($event)' />

    <hr class='my-2' v-if='datepanel && timepanel' />

    <time-panel
      v-if='timepanel'
      :currentMoment='currentMoment'
      @input='setCurrentMoment($event)'
      />
    <hr  class='my-2' />
    <div class='d-flex justify-content-between'>
      <div>
        <a href='#' class='btn btn-sm btn-danger' @click.prevent.stop='$emit("clear")'><fa-icon icon='trash' /></a>
      </div>
      <div>
        <a href='#' class='btn btn-sm btn-secondary' @click.prevent.stop='$emit("now")'>{{ nowTitle }}</a>
        <a href='#' class='btn btn-sm btn-secondary' @click.prevent.stop='$emit("close")'>{{ closeTitle }}</a>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import PanelHeader from './panel-header'
import DatePanel from "./date-panel"
import TimePanel from "./time-panel"

export default {
  props: [ 'value', 'datepanel', 'timepanel', 'nowTitle', 'closeTitle', 'minMoment', 'maxMoment' ],
  components: { PanelHeader, DatePanel, TimePanel },
  data() {
    return {
      dirty: false,
      currentMoment: null,
    }
  },
  computed: {
    valueMoment() {
      if (this.value) {
        const value = moment(this.value)

        return value.isValid() ? value : null
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.dirty = false
        this.currentMoment = moment(this.valueMoment || new Date())
      },
      immediate: true,
    },
    currentMoment: "triggerUpdate"
  },
  methods: {
    // ===================
    // = UI Interactions =
    // ===================
    nextYear() { this.add(1, "year") },
    prevYear() { this.subtract(1, "year") },

    nextMonth() { this.add(1, "month") },
    prevMonth() { this.subtract(1, "month") },

    // ==================
    // = Update Helpers =
    // ==================

    add(number, type) {
      this.updateMoment('add', number, type)
    },

    subtract(number, type) {
      this.updateMoment('subtract', number, type)
    },

    updateMoment(method, number, type) {
      const currentMoment = moment(this.currentMoment)

      this.setCurrentMoment(currentMoment[method].apply(currentMoment, [number, type]))
    },

    setCurrentMoment(aMoment) {
      this.dirty = true
      this.currentMoment = aMoment
    },

    // =======================
    // = V-Model Integration =
    // =======================

    triggerUpdate() {
      if (this.dirty && (!this.valueMoment || (this.valueMoment && !this.currentMoment.isSame(this.valueMoment, "second")))) {
        this.dirty = false
        this.$emit("input", this.currentMoment.toDate())
      }
    },
  }
}

</script>