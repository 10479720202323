<template>
  <a
    :class='["list-group-item", {"list-group-item-action": action, "active": active, "disabled": disabled}]'
    :href='(action ? "#" : null)'
    @click.prevent.stop='handleClick'
    >
    <slot />
  </a>
</template>

<script>

export default {
  props: {
    action: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(e) {
      this.$emit("click")
    },
  }
}

</script>
