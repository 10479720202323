<template>
  <GmapPolygon
    :paths='paths'
    :options='options'
    @click='$emit("click")'
    ></GmapPolygon>
</template>

<script>

export default {
  props: ["geometry", "color"],
  computed: {
    paths() {
      if (!this.geometry) {
        return []
      }
      return this.geometry.paths
    },
    options() {
      return {
        strokeColor: this.color,
        fillColor: this.color
      }
    }
  },
}

</script>