import Sortable from 'sortablejs';

const SELECTOR = "*[data-behavior=report-images-sortable]"

export function start() {
  $(SELECTOR).each((i, el) => {
    const sortable = new Sortable(el, {
      draggable: ".report-image-fields",
      handle: ".report-image-fields--sortable-handle",

      ghostClass: "report-image--sortable-ghost",
      dragClass: "report-image--sortable-dragging",
    })
  })
}