<template>
  <div :class='["map-container bg-light", sizeClass]' @mouseenter='resize' >
    <GmapMap
      :style="mapStyle"
      :zoom='9'
      :center='mapCenter'
      :options='googleMapsOptions'
      :map-type-id='settings.mapTypeId'
      :resize-bus='resizeBus'
      v-if='visible'
      @maptypeid_changed='handleMapTypeIdChanged($event)'
      ref='map'
      >
      <slot />
    </GmapMap>
  </div>
</template>

<script>

import {
  defaultMapPosition as mapCenter,
  defaultMapOptions as googleMapsOptions
} from "../../config"

import Vue from 'vue'

export default {
  props: [ 'settings', 'size' ],
  data() {
    return {
      mapCenter,
      googleMapsOptions,
      visible: true,
      resizeBus: new Vue()
    }
  },
  mounted() {
    this.visible = false

    this.$nextTick(() => this.visible = true, 1000)
  },
  computed: {
    sizeClass() {
      switch (this.size) {
        case "small": return "map-container-sm"
        case "large": return "map-container-lg"
      }
    },
    mapStyle() {
      return {
        display: "inline-block",
        width: "100%",
        height: "100%",
      }
    }
  },
  methods: {
    resize() {
      this.resizeBus.$emit("resize")
    },
    fitBounds(bounds, padding = 10) {
      this.$refs.map.$mapPromise.then((map) => map.fitBounds(bounds, padding))
    },

    setMapCenter(center) {
      this.$refs.map.$mapPromise.then((map) => map.setCenter(center))
    },

    handleMapTypeIdChanged(mapTypeId) {
      this.settings.mapTypeId = mapTypeId
    }
  }
}

</script>