const Selector = {
  COMPONENT: "*[data-behavior=collapsable-radio]",
  CONTAINER: "*[data-behavior=collapsable-radio-container]",
  CONTENT: "*[data-behavior=collapsable-radio-content]",
  INPUT: "input[type=radio]",
}

function showContent($content, animate) {
  if (animate) {
    $content.slideDown()
  } else {
    $content.show()
  }
}

function hideContent($content, animate) {
  if (animate) {
    $content.slideUp()
  } else {
    $content.hide()
  }
}

function refresh($component, animate) {
  $component.find(Selector.CONTAINER).each(function(){
    const $container = $(this)
    const $content = $container.find(Selector.CONTENT)
    const checked = $container.find(Selector.INPUT).prop("checked")
    const contentVisible = $content.is(":visible")

    if (contentVisible && !checked) {
      hideContent($content, animate)
    } else if (!contentVisible && checked) {
      showContent($content, animate)
    }
  })
}

export function setup() {
  $(document).on("change", `${Selector.COMPONENT} ${Selector.INPUT}`, function() {
    const $component = $(this).closest(Selector.COMPONENT)

    refresh($component, true)
  })
}

export function start() {
  $(Selector.COMPONENT).each(function(){
    refresh($(this), false)
  })
}