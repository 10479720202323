const backend = window.localStorage

function buildStorageKey(namespace) {
  return `settings-${namespace}`
}

export function loadSettingsFromNamespace(namespace) {
  const key = buildStorageKey(namespace)
  const stringifiedSettings = backend.getItem(key)

  return stringifiedSettings && JSON.parse(stringifiedSettings)
}

export function saveSettingsForNamespace(namespace, settings) {
  const key = buildStorageKey(namespace)
  const stringifiedSettings = JSON.stringify(settings)

  console.log("Saving settings", key, stringifiedSettings)

  backend.setItem(key, stringifiedSettings)
}
