import { start as startLinkableTabs } from "./linkable_tabs"
import { start as startDueDates } from "./due_dates"
import { setup as setupRemotePopoverContent } from "./remote_popover_content"
import { setup as setupCollapsableRadio, start as startCollapsableRadio } from "./collapsable-radio"

export function start() {
  setupRemotePopoverContent()
  setupCollapsableRadio()

  $(document).on("turbolinks:load", function(){
    startLinkableTabs()
    startDueDates()
    startCollapsableRadio()
  })
}