import moment from 'moment'
import 'moment-timezone'

import { getLocale, getTimeZone } from "../utils"

function setLocale() {
  const locale = getLocale()

  moment.locale(locale);
}

function setTimeZone() {
  const timeZone = getTimeZone()

  moment.tz.setDefault(timeZone)
}

export function start() {
  setLocale()
  setTimeZone()
}