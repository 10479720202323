<template>
  <datetime-picker
    v-model='value'
    :inputId='inputId'
    :datepicker='datePicker'
    :timepicker='timePicker'
    :input-class='inputClass'
    :min-moment='minMoment'
    :max-moment='maxMoment'
    :nowButtonTitle='nowButtonTitle'
    :closeButtonTitle='closeButtonTitle'

    @focus='triggerInputEvent("focus")'
    @blur='triggerInputEvent("blur")'
    />
</template>

<script>

import moment from 'moment'

import { customFormControl } from "../mixins"

import { DatetimePicker } from "../components/datetime-picker"

export default {
  props: ['translations', 'inputType', 'minDate', 'maxDate'],
  mixins: [ customFormControl ],
  components: { DatetimePicker },
  data() {
    return {
      originalType: null
    }
  },
  mounted() {
    this.originalType = this.$input.attr("type")
    this.$input.attr("type", "text")
  },
  beforeDestroy() {
    this.$input.attr("type", this.originalType)
  },
  computed: {
    nowButtonTitle() {  return this.inputType == "date" ? this.translations.today : this.translations.now },
    closeButtonTitle() { return this.translations.close },
    datePicker() { return this.inputType == "date" || this.inputType == "datetime" },
    timePicker() { return this.inputType == "time" || this.inputType == "datetime" },
    minMoment() { return (this.minDate) ? moment(this.minDate) : null },
    maxMoment() { return (this.maxDate) ? moment(this.maxDate) : null },
  },
  methods: {
    triggerInputEvent(named) {
      this.$input.trigger(`vue-${named}`)
    }
  },
}

</script>