import { Controller } from 'stimulus'
import Vue from 'vue'

import Popover from "src/search_field/popover"

export default class SearchFieldController extends Controller {
  static targets = [ "field", "popover", "popoverContent" ]

  get fields() { return JSON.parse(this.data.get("fields")) }

  get query() { return this.fieldTarget.value }
  set query(value) { return this.fieldTarget.value = value }

  get hasActiveSuggestion() { return !!this.activeSuggestion }

  get fieldActive() { return this.data.get("fieldActive") != "false" }
  set fieldActive(active) {
    this.data.set("fieldActive", active ? "true" : "false")
    this.updatePopoverVisibility()
  }

  get popoverActive() { return this.data.get("popoverActive") != "false" }
  set popoverActive(active) {
    this.data.set("popoverActive", active ? "true" : "false")
    this.updatePopoverVisibility()
  }

  get popoverVisible() { return this.popoverActive || this.fieldActive }

  connect() {
    this.setupEventBus()
    this.setupPopover()

    this.queryUpdated()
  }

  // ===========
  // = Actions =
  // ===========

  showSuggestions() {
    this.fieldActive = true
  }

  updateSuggestions() {
    const { query } = this

    this.queryUpdated(this.query)
  }

  hideSuggestions() {
    this.fieldActive = false
  }

  handleKey(e) {
    switch (e.which) {
    case 13:
      this.handleEnter(e)
      break

    case 38:
      this.handleUp(e)
      break

    case 40:
      this.handleDown(e)
      break

    }
  }

  handleEnter(e) {
    if (!this.hasActiveSuggestion) { return }

    this.confirmSelection()

    e.preventDefault()
  }

  handleUp(e) {
    this.emitEvent("highlightPrevious")
    e.preventDefault()
  }

  handleDown(e) {
    this.emitEvent("highlightNext")
    e.preventDefault()
  }

  // ====================
  // = Popup Visibility =
  // ====================

  updatePopoverVisibility() {
    if (this.popoverVisible) {
      this.popoverTarget.classList.remove("d-none")
    } else {
      this.popoverTarget.classList.add("d-none")
    }
  }

  // =========
  // = Setup =
  // =========

  setupEventBus() {
    this.eventBus = new Vue()

    this.eventBus.$on("popoverActive", (data) => this.popoverActive = data.active )

    this.eventBus.$on("activeSuggestion", (data) => this.activeSuggestion = data?.suggestion)
    this.eventBus.$on("selectSuggestion", (data) => {
      this.activeSuggestion = data.suggestion;

      this.confirmSelection()
      this.fieldTarget.focus()
    })
  }

  setupPopover() {
    const el = this.popoverContentTarget
    const { eventBus, fields } = this

    this.popover = new Vue({
      el,
      render: (h) => h(Popover, {
        attrs: { eventBus, fields }
      })
    })
  }

  // =============
  // = Selection =
  // =============

  confirmSelection() {
    this.query = this.activeSuggestion
    this.queryUpdated()
  }

  // ==========
  // = Events =
  // ==========

  emitEvent(name, data = {}) {
    this.eventBus.$emit(name, data)
  }

  queryUpdated() {
    const { query } = this

    this.emitEvent("queryUpdated", { query })
  }

}