require("./bootstrap/custom_file_inputs.js")
require("./bootstrap/tooltips.js")

require("./bootstrap").start()
require("./font-awesome").start()
require("./timeago").start()
require("./status_watcher").start()
require("./selectable-modals").start()
require("./jquery").start()
require("./moment").start()
require("./stimulus").start()
require("./vue").start()
require("./sortablejs").start()
require("./turbolinks").start()

require("./bioareas").start()
