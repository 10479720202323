
let instance
export default class BioAreas {
  static instance() {
    if (!instance){
      instance = new BioAreas()
    }

    return instance
  }
}