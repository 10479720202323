<template>
  <div :class='["form-group", {"form-group-invalid": hasFieldErrors}]'>
    <label v-if='labelHTML' :for='inputId' v-html='labelHTML'></label>
    <div class='input-group'>
      <div class='input-group-prepend' v-if='showSelectionIcon'>
        <div class='input-group-text'>
          <fa-icon :icon='selectionIcon.replace("_", "-")' />
        </div>
      </div>
      <input
        ref='input'
        type='text'
        :id='inputId'
        :class='["form-control", { "bg-light": !!selection && !inputFocused, "is-invalid": hasFieldErrors }]'
        :disabled='creating'
        :placeholder='inputPlaceholder || translations.input_placeholder'
        v-model='inputValue'
        @input='handleInputValueChanged'
        @focus='handleInputFocused'
        @blur='handleInputBlurred'
        />

      <div class="input-group-append" v-if='showRemoveSelection'>
        <a @click.prevent='handleRemoveSelection' href='#' class='btn btn-danger'>
          <fa-icon icon='times'></fa-icon>
        </a>
      </div>
    </div>
    <div class='invalid-feedback d-block' v-if='hasFieldErrors' v-html='targetFieldErrorsHTML'></div>
    <Dropdown v-if='showDropdown' :target='this.$refs.input' :hovering.sync='hoveringDropdown' class='list-group'>
      <ListGroupItem v-if='showCurrentSelection' :action='false' :disabled='true'>
        <small class='d-block text muted'>{{ translations.current_selection }}</small>
        <Result :name='selection.name' :description='selection.description'></Result>
      </ListGroupItem>

      <ListGroupItem
        v-for='result in searchResults'
        :key='result.id'
        :active='selection && selection.id == result.id'
        @click='handleResultSelected(result)'
        >
        <Result :name='result.name' :description='result.description' :active='selection && selection.id == result.id'></Result>
      </ListGroupItem>

      <ListGroupItem :action='false' :disabled='true' v-if='loading'>
        <div class='d-flex align-items-center justify-content-center'>
          <Spinner class='mr-2' :small="true" /> {{ translations.loading }}
        </div>
      </ListGroupItem>
      <ListGroupItem v-if='showNoResults' class='text-center' :action='false' :disabled='true'>
        <strong class='text-muted'>{{ translations.no_results }}</strong>
      </ListGroupItem>
      <ListGroupItem @click='handleCreateClicked' v-if='showCreate' class='bg-light'>
        <small class='d-block text-muted'>{{ translations.create }}</small>
        <strong>{{ inputValue }}</strong>
      </ListGroupItem>
    </Dropdown>

  </div>
</template>

<script>

import { debounce } from 'lodash'
import { Spinner, ListGroupItem } from "../components/bootstrap"

import { fetchSelectables } from "../api"
import { findTarget } from "../utils/targetable"

import { Result, Dropdown } from "../components/selectable"

export default {
  components: { Spinner, ListGroupItem, Dropdown, Result },
  props: ["translations", "source-url", "label", "field-errors", "multiple", "input-required", "current", "target", "create-target", "selection-icon"],
  data() {
    return {
      labelHTML: "",
      targetFieldErrorsHTML: "",
      inputPlaceholder: "",

      selection: null,
      inputFocused: false,
      hoveringDropdown: false,
      forceCloseDropdown: false,
      inputValue: "",

      creating: false,

      loading: false,
      searched: false,
      error: null,
      searchResults: [],
    }
  },
  mounted() {
    this.initializeWithTarget()
    if (this.current) {
      this.selection = this.current
      this.showSelection()
    }
    if (this.fieldErrors) {
      this.currentFieldErrors = this.fieldErrors
    }
  },
  destroyed() {
    this.targetFormGroup.show()
  },
  computed: {
    showDropdown() { return !this.forceCloseDropdown && (this.inputFocused || this.hoveringDropdown) },
    showCreate() { return this.canCreateItem && !this.hasExactMatch && this.inputValue != "" },
    showNoResults() { return this.inputValue != "" && !this.loading && this.searched && !this.searchResults.length },
    showRemoveSelection() { return !!this.selection },
    showCurrentSelection() { return !!this.selection },
    showSelectionIcon() { return !!this.selection && this.selectionIcon },
    hasExactMatch() {
      if (this.selection) {
        if (this.selection.name == this.inputValue) {
          return true
        }
      }
      for (let i = 0; i < this.searchResults.length; i++) {
        let result = this.searchResults[i];

        if (result.name == this.inputValue) {
          return true
        }
      }

      return false
    },
    isSelectionIncludedInResults() {
      if (!this.selection) { return false; }

      for (let i = 0; i < this.searchResults.length; i++) {
        let result = this.searchResults[i];

        if (result.id == this.selection.id) {
          return true
        }
      }

      return false
    },
    inputId() { return `vue-component-${this._uid}` },
    targetElement() { return findTarget(this.target, this.$el) },
    targetFormGroup() { return this.targetElement.closest(".form-group") },
    canCreateItem() { return this.createTarget && this.createTarget != "" },
    hasFieldErrors() { return this.targetFieldErrorsHTML },
  },
  watch: {
    selection() {
      const inputValue = this.selection ? this.selection.id : null

      this.targetElement.val(inputValue)
    },
    showDropdown() {
      if (!this.showDropdown && this.selection) {
        this.showSelection()
      }
    }
  },
  methods: {
    // ===================
    // = Target Handling =
    // ===================

    initializeWithTarget() {
      this.labelHTML = this.targetFormGroup.find("label").html()
      this.targetFieldErrorsHTML = this.targetFormGroup.find(".invalid-feedback").html()
      this.inputPlaceholder = this.targetElement.attr("placeholder")

      this.targetFormGroup.hide()
    },

    // =============
    // = Searching =
    // =============
    search(value)  {
      this.loading = true
      this.searched = true
      this.searchResults = []
      this.error = null
      this.debouncedSearch(value)
    },
    debouncedSearch: debounce(function(value){
      fetchSelectables(this.sourceUrl, { q: value })
        .then((results) => this.searchResults = results)
        .catch((error) => this.error = error)
        .finally(() => this.loading = false )
    }, 250),

    forceClose() {
      this.$refs.input.blur()
      this.hoveringDropdown = false
      this.forceCloseDropdown = true
    },

    showSelection() {
      this.inputValue = this.selection ? this.selection.name : ""
    },

    // ==================
    // = Event Handling =
    // ==================
    handleCreateClicked() {
      this.forceClose()
      this.creating = true
      this.selection = null

      $(this.createTarget).trigger("selectable:createItem", [this.inputValue, (item) => {
        if (item) {
          this.selection = item;
          this.showSelection()
        }

        this.creating = false
      }])
    },
    handleInputValueChanged() {
      if (this.inputValue != ""){
        this.search(this.inputValue)
      } else {
        this.searchResults = []
      }
    },
    handleInputFocused() {
      this.inputFocused = true;
      this.forceCloseDropdown = false
    },
    handleInputBlurred() {
      this.inputFocused = false
    },
    handleResultSelected(result) {
      this.selection = result

      // this.showSelection()
      this.forceClose()
    },
    handleRemoveSelection() {
      this.selection = null
      this.showSelection()
    },
  },
}

</script>