export function initBootstrapComponent(component, options) {
  const $components = $(`*[data-toggle=${component}]`)

  if ($components.length > 0) {
    $components[component].apply($components, [options])

    $(document).one("turbolinks:before-cache", (e) => {
      $components[component].apply($components, ['dispose'])
    })
  }

  return $components
}