export default class Controller {
  constructor($element) {
    this.element = $element
    this.modal = this.element.find(".modal")

    this._setupListeners()
  }

  destroy() {
    this._detachListeners()
  }

  assignInputValue() {
    throw "Not Implemented!"
  }

  // ==================
  // = Modal Handling =
  // ==================

  _handleOpen() {
    this.element.find("form").trigger("reset")
    this.assignInputValue()

    this.modal.modal("show")
  }

  _handleClose() {
    this._runCallback()
  }

  _closeModal() {
    this.modal.modal("hide")
  }

  // ===================
  // = Form Submission =
  // ===================

  _handleResponse(event) {
    const [data, status, xhr] = event.detail

    if (data.success) {
      this._handleSuccess(data.selectable)
    } else {
      this._updateForm(data.template)
    }
  }

  _handleSuccess(contact) {
    this._runCallback(contact)
    this._closeModal()
  }

  _updateForm(html) {
    const $content = this.element.find(".modal-content")
    $content.html(html)

    this.modal.modal("handleUpdate")
  }

  // =====================
  // = Callback Handling =
  // =====================

  _runCallback(contact) {
    if (this.callback){
      this.callback(contact)
      this.callback = null
    }
  }

  // =====================
  // = Listener Handling =
  // =====================

  _setupListeners() {
    this.element.on("selectable:createItem", (e, inputValue, callback) => {
      this.inputValue = inputValue
      this.callback = callback

      this._handleOpen()
    })

    this.modal.on("hide.bs.modal", () => {
      this._handleClose()
    })

    this.element.on("ajax:success", (e) => {
      this._handleResponse(e)
    })
  }

  _detachListeners() {
    this.element.off("selectable:createItem")
  }
}

