import Controller from "./controller"

export default class ContactController extends Controller {
  assignInputValue() {
    this.element.find("input#contact_name").val(this.inputValue)
  }
}


// export function start() {
//   let currentModals = []
//
//   $(document).on("turbolinks:load", function() {
//     currentModals.forEach((modal) => modal.destroy())
//     currentModals = []
//
//     $('*[data-behaviour=contact-modal]').each(function() {
//       const $element = $(this)
//
//       currentModals.push(new ContactModal($element))
//     })
//   })
// }