<template>
  <GmapMarker
    v-if='area'
    :position='position'
    @click='$emit("click", area)'
    />
</template>

<script>

export default {
  props: {
    area: {
      required: true,
    }
  },
  computed: {
    position() { return this.area.centroid },
  }
}

</script>