import AppConfig from 'app-config'

// ===============
// = Google Maps =
// ===============

export const googleAPIKey = AppConfig.google_api_key

export const defaultMapPosition = {lat: 48.136583333333, lng: 13.964416666667}

export const defaultMapOptions = {
  mapTypeControl: true,
  streetViewControl: false,
  scaleControl: true,
}

export var defaultMapSettings = {
  color: "solid_red",
  mapTypeId: "hybrid",
  showUserLocation: false,
  areaPins: false,
}

