import Vue from 'vue'

import { loadSettingsFromNamespace, saveSettingsForNamespace } from "./storage"

export function createSettingsMixin(namespace = "global", defaults) {
  const settings = loadSettingsFromNamespace(namespace) || {...defaults};

  const observableSettings = Vue.observable(settings)

  return {
    data() {
      return { settings: observableSettings };
    },
    watch: {
      settings: {
        handler() {
          saveSettingsForNamespace(namespace, this.settings)
        },
        deep: true
      }
    }
  }
}