export const customFormControlMixin = {
  data() {
    return {
      $input: null,
      $currentValue: null,
      labelTarget: null,
      inputClass: "",
      value: null,
    }
  },
  mounted() {
    this.setupFormInput()
    this.hideInput()
  },
  computed: {
    inputId() { return `vue-component-${this._uid}` }
  },
  watch: {
    value: "writeValue",
  },
  methods: {
    // =========
    // = Setup =
    // =========
    setupFormInput() {
      this.$formGroup = $(this.$el).closest(".form-group")
      this.$input = this.$formGroup.children("input, select, textarea")
      this.$label = this.$formGroup.children("label")
      this.$label.attr("for", this.inputId)
      this.inputClass = this.$input.attr("class")

      this.loadValue()
      this.$currentValue = this.value

      this.$input.on("change", () => this.loadValue())
    },

    // ==================
    // = Value Handling =
    // ==================

    loadValue() { this.value = this.$input.val(); console.log("Loaded changed value", this.value) },
    writeValue() { this.$input.val(this.value) },

    // =========================
    // = Visibility Management =
    // =========================

    hideInput() { this.$input.hide() },

    // ====================
    // = Label Management =
    // ====================

    updateLabelTarget() {
      this.$label.attr("for", this.labelTarget)
    },
  }
}