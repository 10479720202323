<template>
  <Geometry
    v-if='area'
    :geojson='area.geometry'
    :color='color'
    @click='$emit("click", area)'
    />
</template>

<script>

import { buildLatLngFromCoordinate as buildCoord } from "../../../utils/map_utils"

import { Geometry } from "../geometry"

import { colorForArea } from './colorization'

export default {
  props: {
    area: {
      required: true,
    },
    colorize: {
      default: "access",
      type: String,
    }
  },
  components: { Geometry },
  computed: {
    color() { return colorForArea(this.colorize, this.area) },
  },
}

</script>