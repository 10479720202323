<template>
  <select :value='value' @input='$emit("input", $event.target.value)' class="custom-select">
    <slot />
  </select>
</template>

<script>

export default {
  props: ['value']
}

</script>