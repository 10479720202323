export function start() {
  $("*[data-behaviour=linkable-tabs]").each(function() {
    const $tabs = $(this)
    const id = window.location.hash


    // Important! Do not lookup links via $(id), it would
    // allow adversaries to click arbitrary links on the page - BAD!
    $tabs.find("a.nav-link").each(function(){
      const $link = $(this)

      if ($link.attr("href") == id) {
        $link.trigger("click")
      }
    })
  })
}