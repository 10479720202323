import { Controller } from 'stimulus'

import { createVueInstance } from "../../vue"

export default class VueController extends Controller {
  connect() {
    if (this._isTurbolinksPreview()) {
      // Do not create instances on turbolinks preview
      return
    }

    this._ensureVueContainer()
    this._installVueComponent()
  }

  disconnect() {
    this._destroyVueComponent()
    this._removeVueContainer()
  }

  // ==========================
  // = Vue Container Handling =
  // ==========================

  _ensureVueContainer() {
    this.vueContainer = this._findExistingContainer() || this._createNewContainer()
  }

  _removeVueContainer() {
    if (this.vueContainer) {
      this.vueContainer.remove()
      this.vueContainer = null
    }
  }

  _findExistingContainer() {
    const existingChildren = $(this.element).children()

    if (existingChildren.length > 0) {
      const previousContainer = $(existingChildren.get(0))

      return previousContainer.html("")
    } else {
      return null
    }
  }

  _createNewContainer() {
    return $("<div>").appendTo(this.element)
  }

  // ==========================
  // = Vue Component Handling =
  // ==========================

  _installVueComponent() {
    const $element = $(this.element)
    const componentName = $element.data("component-name")
    const props = Object.assign({}, $element.data("props"))

    this.vueComponent = createVueInstance(this.vueContainer.get(0), componentName, props)
  }

  _destroyVueComponent() {
    if (this.vueComponent) {
      this.vueComponent.$destroy()
    }
  }

  // ===========
  // = Helpers =
  // ===========

  _isTurbolinksPreview() {
    return document.documentElement.hasAttribute("data-turbolinks-preview")
  }
}