import { buildLatLngFromCoordinates } from "../utils/map_utils"

function parseArea(area) {
  let { geometry, centroid } = area

  if (!geometry || !centroid){
    return null;
  }

  geometry.paths = geometry.coordinates.map((path) => buildLatLngFromCoordinates(path))
  const parsedCentroid = {lat: centroid[0], lng: centroid[1]}

  const parsedArea = {...area, geometry, centroid: parsedCentroid };

  return parsedArea
}

export function fetchAreas(url) {
  return fetch(url)
    .then(resp => resp.json())
    .then(json => json.map((area) => parseArea(area)))
    .then(json => json.filter((area) => !!area))
}

export function fetchArea(url) {
  return fetch(url)
    .then(resp => resp.json())
    .then(json => parseArea(json))
}