<template>
  <div>
    <div class='list-group' @mouseenter='handleMouseenter' @mouseleave='handleMouseleave'>
      <div
        v-for='field, idx in suggestions'
        :class='["list-group-item list-group-item-action", {"active": idx == highlightIndex}]'
        @click='selectSuggestion(field)'
        >
        <strong>{{ field.head }}</strong>{{ field.tail }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [ "eventBus", "fields" ],
  data() {
    return {
      queryString: "",
      highlightIndex: 0
    }
  },
  computed: {
    head() { return this.splitIndex == -1 ? "" : this.queryString.substring(0, this.splitIndex + 1) },
    splitIndex() { return this.queryString.lastIndexOf(" ") },
    suggestable() { return this.splitIndex == -1 ? this.queryString : this.queryString.substr(this.splitIndex + 1) },
    activeSuggestion() { return this.suggestions.length > 0 ? this.suggestions[this.highlightIndex] : null },
    suggestions() {
      const { suggestable } = this

      let matches = []

      if (suggestable.length > 0) {
        matches = this.fields.filter((f) => f.toLowerCase().startsWith(suggestable.toLowerCase()))
      }

      return matches.sort((a, b) => b - a).map((field) => {
        const head = field.substr(0, suggestable.length)
        const tail = field.substr(suggestable.length)
        const suggestion = `${this.head}${field} `

        return { head, tail, suggestion }
      })
    },
  },
  mounted() {
    this.eventBus.$on("queryUpdated", this.updateQuery.bind(this))
    this.eventBus.$on("highlightNext", this.highlightNext.bind(this))
    this.eventBus.$on("highlightPrevious", this.highlightPrevious.bind(this))
  },

  watch: {
    activeSuggestion() {
      this.eventBus.$emit("activeSuggestion", this.activeSuggestion)
    }
  },

  methods: {
    updateQuery(data) {
      this.queryString = data.query
      this.highlightIndex = 0
    },

    selectSuggestion(field) {
      this.eventBus.$emit("selectSuggestion", field)
    },

    highlightNext() {
      if (this.highlightIndex < this.suggestions.length - 1) {
        this.highlightIndex++
      }
    },

    highlightPrevious() {
      if (this.highlightIndex > 0) {
        this.highlightIndex--
      }
    },

    handleMouseenter() {
      this.eventBus.$emit("popoverActive", {active: true })
    },

    handleMouseleave() {
      this.eventBus.$emit("popoverActive", {active: false })
    }


  },
}

</script>