import { Controller } from 'stimulus'
import $ from 'jquery'

import { Attachment } from 'trix'

import { stop as stopEvent } from "../../utils/events"

export default class SectionEditorController extends Controller {
  static targets = ["databaseButton", "trixContainer"]

  get i18n() { return JSON.parse(this.data.get("i18n")) }

  get datasetSelectorURL() { return this.data.get("dataset-selector-url") }

  get toolbarTarget() { return this.trixContainerTarget.getElementsByTagName("trix-toolbar")[0] }
  get editorTarget() { return this.trixContainerTarget.getElementsByTagName("trix-editor")[0] }

  get editor() { return this.editorTarget.editor }

  get buttonContainerTarget() { return this.toolbarTarget.querySelector("*[data-target=external-toolbar-buttons]") }
  get datasetModalTarget() { return document.querySelector(this.data.get("dataset-modal")) }

  // ===========
  // = Actions =
  // ===========

  setupToolbar() {
    if (!this.hasDatabaseButtonTarget) {
      this._injectDataAttachmentButtonIntoToolbar()
    }
  }

  showDatasetModal(e) {
    stopEvent(e)

    $(this.datasetModalTarget).modal("show")
  }

  attachData(e) {
    stopEvent(e)

    const { detail } = e
    const json = detail[0]

    this._insertAttachment(json)
  }

  updateAttachment(e) {
    stopEvent(e)

    const { detail } = e
    const json = detail[0]

    this._updateAttachment(json)
  }


  // ===============
  // = UI Handling =
  // ===============

  _injectDataAttachmentButtonIntoToolbar() {
    const btn = document.createElement("button")

    btn.title = this.i18n.data_attachment_button_title

    btn.classList.add("btn", "btn-light")
    btn.setAttribute("tabindex", "-1")
    btn.setAttribute("type", "button")
    btn.dataset.action = "remote-modal#show"
    btn.dataset.target = "section-editor.databaseButton"
    btn.dataset.remoteModalUrl = this.datasetSelectorURL

    btn.innerHTML = "<i class='fa fa-database'></i>"

    this.buttonContainerTarget.appendChild(btn)
  }

  // ====================
  // = Tribute Handling =
  // ====================

  _insertAttachment(object) {
    const attachment = new Attachment(object)

    this.editor.recordUndoEntry("Insert Attachment")
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _updateAttachment(json) {
    const { sgid, content, dom_id } = json

    // Update editor model
    const attachment = this._findAttachmentBySgid(sgid)
    attachment.setAttributes({ content: content })

    // Trigger rerender
    this.editor.setSelectedRange(this.editor.getPosition())
  }
  _findAttachmentBySgid(sgid) {
    return this._getTrixAttachments().find((attachment) => { return attachment.getAttribute("sgid") == sgid })
  }

  _getTrixAttachments() {
    return this.editor.getDocument().getAttachments()
  }


}