
export function fetchMarkdownPreview(url, markdown) {
  return new Promise((resolve, reject) => {

    Rails.ajax({
      url,
      data: $.param({ preview: { markdown } }),
      type: "POST",
      success: (data) => {
        resolve(data)
      },
      error: (xhr, textStatus, error) => {
        reject(textStatus)
      }
    })
  }).then((json) => {
    if (json.success) {
      return json.html
    } else {
      throw json.error
    }
  })

}
