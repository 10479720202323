function defaultColorForType(type) {
  switch (type) {
    case "access":  return "red";
    case "tag":     return "white";
    default:        return "yellow";
  }
}

export function colorForArea(type, area) {
  switch (type) {
    case "solid_red": return "red"
    case "solid_yellow": return "yellow"
    case "access": return (area && area.color.access) || defaultColorForType(type)
    case "tag": return (area && area.color.tag) || defaultColorForType(type)
    default: return defaultColorForType(type)
  }
}